<template>
  <div class="base-timer">
    <span class=" m-10 font-semibold text-t3 text-xl">{{ formattedTimeLeft }}</span>
  </div>
</template>

<script>
// import router from '../router';

import Swal from 'sweetalert2';


const TIME_LIMIT = 0;

export default {
  data() {
    return {
      timePassed: 0,
      timerInterval: null
    };
  },
  props:{
    timeUP:{
      type:Number
    }
  },

  computed: {

    formattedTimeLeft() {
      const timeLeft = this.timeLeft;
      let hours = Math.floor(timeLeft / 3600);
      let minutes = Math.floor(timeLeft / 60)%60;
      let seconds = timeLeft % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }

      return `${hours}:${minutes}:${seconds}`;
    },

    timeLeft() {
      return TIME_LIMIT - this.timePassed;
    },

    timeFraction() {
      const rawTimeFraction = this.timeLeft / TIME_LIMIT;
      return rawTimeFraction - (1 / TIME_LIMIT) * (1 - rawTimeFraction);
    },

  },

  watch: {
    timeLeft(newValue) {
      if (newValue === this.timeUP*60) {
        this.onTimesUp();
      }
    }
  },

  mounted() {
    this.startTimer();
  },

  methods: {
    onTimesUp() {
      clearInterval(this.timerInterval);
        Swal.fire({
        icon:"warning",
        title:"Time is up!",
        text:"It is advised to end the assessment now.",
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 10000,})
    },

    startTimer() {
      this.timerInterval = setInterval(() => (this.timePassed -= 1), 1000);
    }
  }
};
</script>

<style scoped >

</style>