<template>
    <div v-if="displayImage" class="fixed w-screen flex flex-col h-screen z-50 left-0 top-0" style="background-color:#212D638F;">
        <button type="button"
                        class="box-content w-4 h-4 p-1 mt-10 ml-auto mr-10 mb-auto text-white border-none rounded-none focus:shadow-none focus:outline-none  hover:text-black hover:opacity-75 hover:no-underline"
                        @click='closeImage()'>Close</button>
        <div class="my-auto mx-auto h-full flex justify-center py-10 overflow-auto">
            <img class="h-full object-cover "  :src="srcDisplay" />
        </div>
    </div>
<Transition name="slide-fade" appear>
<div class="bg-white text-t3 container"> 
    <div class="rounded shadow-t3 items-center relative md:mt-0"> 

            <!-- Assessment Customer Profile  -->
        <div class="grid grid-cols-1 md:grid-cols-2 items-center gap-5 md:gap-44 -2 mt-6">
            <div aria-label="card 1"
                class="focus:outline-none bg-white p-6 space-y-1 dark:text-white">
                <div class="flex items-center justify-between pb-5 m-0">
                    <img :src="assessment.customer_image" alt="user" class=" w-20 h-20 rounded-xl" />
                    <div class="flex items-start justify-between w-full overflow-hidden">
                        <div class="pl-3 w-5/6">
                            <p class="focus:outline-none text-xl font-medium leading-5">{{ capitalizeLastWord(assessment.customer_name) }}</p>
                            <p class="focus:outline-none text-sm leading-normal pt-2 text-gray-500 dark:text-t3-nightText">{{assessment.customer_id}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col align-center  p-6 w-full">
                <a href="https://objectstorage.me-dubai-1.oraclecloud.com/n/accelaero/b/dxb_external_t3a_elp_prod/o/static%2FICAO-Descriptors.pdf" target="_blank" class="ml-auto mr-8"><button class=" font-medium bg-t3 p-3 text-white rounded-md hover:text-white hover:bg-t3-secondary">Show ICAO Descriptors</button></a>
                <div class="ml-auto mr-8 flex items-center my-3 p-3" >
                    <!-- <input class="mr-2"  type="checkbox" v-model="ticked" name="tickbox" id=""> -->
                    <!-- <label for="tickbox">Show Evaluation Form</label> -->
                    <BaseTimer :timeUP="settings.assessment_duration"/>
                    <!-- <audio-recorder
                        upload-url="YOUR_API_URL"
                        :attempts="3"
                        :time="2"
                        :headers="headers"
                        :before-recording="callback"
                        :pause-recording="callback"
                        :after-recording="callback"
                        :select-record="callback"
                        :before-upload="callback"
                        :successful-upload="callback"
                        :failed-upload="callback"/> -->
                </div>
            </div>
        </div>
            <!-- Question Display Tabs -->
        <div class="shadow-t3 bg-white p-2 px-36 rounded">
            <div v-if="summary" class="">
                <h1 class="text-3xl font-semibold text-t3">Recording Summary</h1>
                <div  class="my-32">
						<div v-if="recordings.length === 0" class="flex hover:bg-gray-100 w-full rounded px-2">
                            <div class="text-xl w-full font-semibold text-center text-t3 my-24">
								<svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
								<path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
								</svg>
								<p>It appears this assessment has no recordings</p>
                            </div>
						</div>
                                <div v-if="recordings.length !== 0" class="flex  w-full rounded  flex-col gap6">
									<button @click="changeURL" v-for="recording in recordings" class="text-t3 flex gap-2 px-2 hover:bg-gray-100 items-center" :key="recording.id" :name="tasks.find(task => task.id === recording.task ).name" :value="recording.url">											
                                        <PulseLoader :loading="recording.loader=== 'load'"  color="#0E2D5B" size="7px"/>
										<svg v-if="recording.loader !== 'load'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" class="w-5 h-5" :class ="{'text-t3-secondary':AudioAttrs.src===recording.recording} ">
										<path stroke-linecap="round" stroke-linejoin="round" d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z" />
										</svg>
										<h2 class=" text-xl my-2"> {{tasks.find(task => task.id === recording.task ).name}} Task</h2>
                                        <p v-if="recording.loader === 'yes'" class="ml-auto text-sm text-t3-secondary">Done</p>
									</button>
								</div>

						</div>
						<div v-if="AudioAttrs.src" class="border-t items-center justify-center py-3 flex w-full ">
							<span  @click="playButtn()" class="ml-12 border rounded-full border-t3 p-1 cursor-pointer text-t3">
								<svg v-if="!buttonP" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 ml-1 my-0.5 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z" />
								</svg>
								<svg v-if="buttonP" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 mx-0.5 my-0.5 h-6">
								<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
								</svg>
							</span>
                            <AudioPlayer id='audio' :option="AudioAttrs" @play="buttonP = true" @pause="buttonP=false" class="w-64 "/>
						</div>
                    <div v-if="!uploading" class="flex justify-end gap-6">
                        <button @click="finishAssessment()" class="my-6 font-medium bg-t3 p-3 px-6 text-white rounded-md hover:text-white hover:bg-t3-secondary">Finish Assessment</button>
                    </div>
            </div>
        <ul v-if="!summary" class="nav nav-tabs hidden flex flex-col md:flex-row flex-wrap list-none border-b-0 pl-0 mb-4" id="tabs-tab" role="tablist">
            <li v-for="(task,index) in sortedTask() " :key="task.id" class="nav-item" role="presentation">
                <button :href="'#a'+task.id" :id="task.id" :value="task.id " class="nav-link block font-medium leading-tight text-base border-x-0 border-t-0 border-b-2 border-transparent px-6 py-3 my-2 hover:border-transparent hover:bg-gray-100 focus:border-transparent" data-bs-toggle="pill" :data-bs-target="'#a'+task.id" role="tab" :aria-controls="'tabs-'+task.id"
                aria-selected="true" :class="{ active : index === 0 }">{{task.name}}</button>
            </li>
            </ul>

            <!-- Tab Content -->

            <div v-if="!summary" class="flex justify-between" >
                <div v-for="(task,index) in sortedTask() " :key="index">
                    <h1 class="font-semibold text-center" :class="{'text-t3 text-xl':currTab === task.id, 'text-gray-400 text-md':currTab !== task.id }">{{task.name}}</h1>
                </div>
            </div>
            <div v-if="!summary" class="tab-content" id="tabs-tabContent">
            <div v-for="(task,index) in sortedTask() " :key="index" class="tab-pane fade" :class="{ 'active show': index === 0 }" :id="'a'+task.id" role="tabpanel" aria-labelledby="tabs-text-tab">
                <div>     
                <ul>
                    <li class="text-xl font-semibold text-center text-t3 my-64" v-if="questionTask(task.id).length === 0">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-12 w-12 mx-auto my-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <p>It appears you have encountered an error!! It appears there are no questions here yet.</p>
                     </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'txt').length !== 0">
                    </li>
                    <li class=" border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'txt')" :key="question.id">
                        <span v-if="question.type === 'txt'">{{question.text}}</span>
                    </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'img').length !== 0">
                    </li>
                    <li class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        <div class=" border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'img')" :key="question.id">
                                <img class="w-44 object-cover cursor-zoom-in rounded hover:drop-shadow-xl hover:scale-150" @click="enlargeImage(question.content)" v-if="question.type == 'img'" :src="question.content" :alt="question.description"/>
                            
                        </div>
                    </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'audio').length !== 0">
                    </li>
                    <li >
                        <div class="grid grid-cols-2 w-full  content-center text-lg text-t3 border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'audio')" :key="question.id">
                            <audio :src="question.content" controls><source type="audio/mp3"></audio>
                            <p class="ml-auto mr-8">{{question.description}}</p>
                        </div>
                    </li>
                    <li class="text-xl font-semibold text-t3 my-8" v-if="questionFilter(questionTask(task.id),'pdf').length !== 0">
                    </li>
                    <li>
                        <div class=" border-b p-3 border-t3-body" v-for="question in questionFilter(questionTask(task.id),'pdf')" :key="question.id">
                            <a :href="question.content" download>Download {{question.description}}</a>
                        </div>
                    </li>
                    <li class="flex flex-col items-center justify-center align-center mt-6 mb-2">  
                        <h3 class="text-lg text-t3 mb-2"> <span v-if="!recordingNow">Record Audio for Task</span>  <span v-if="recordingNow">Recording ...</span></h3>
                              <button v-if="!recordingNow" @click="startRecording(task.name)" class="rounded-full bg-t3 hover:bg-t3-secondary text-white p-6">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z" />
                            </svg>
                            </button>
                            <button v-if="recordingNow"  class="rounded-full bg-t3-cancel cursor:default text-white p-6"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M5.25 7.5A2.25 2.25 0 017.5 5.25h9a2.25 2.25 0 012.25 2.25v9a2.25 2.25 0 01-2.25 2.25h-9a2.25 2.25 0 01-2.25-2.25v-9z" />
                            </svg>
                            </button>

                    </li>
                    <li class="flex justify-end gap-6">
                        <button @click="submitReport(task.id,task.name)" class="my-6 font-medium bg-t3 p-3 px-6 text-white rounded-md hover:text-white hover:bg-t3-secondary">Next</button>
                    </li>
                    
                </ul>
                </div>
            </div>

        </div> 
        </div>
        
    </div>


</div>
</Transition>

</template>
<style>
		.audio__player-play{
		display:none !important;
	}
</style>
<script>
import { watchEffect } from 'vue'
import { mapState, mapActions } from 'vuex'
import AssessmentService from "../services/AssessmentsServices"
import BaseTimer from "@/components/BaseTimer";
import MicRecorder from 'mic-recorder-to-mp3';
import AudioPlayer from 'vue3-audio-player'
import 'vue3-audio-player/dist/style.css'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
// import AudioRecorder from "vue-audio-recorder"
// import FileInput from '@/components/FileInput.vue'
import router from '../router';
import Swal from 'sweetalert2';
import ReportServices from '@/services/ReportServices'
import RecordingService from "../services/RecordingServices"
    export default {
		props: {
			id: {
				type: String,
				required: true
			}
		},
          components: {
            AudioPlayer,
            PulseLoader,
    BaseTimer,
    // AudioRecorder: AudioRecorder.AudioRecorder,
    // AudioPlayer: AudioRecorder.AudioPlayer,
  },
        data() {
			return {
                summary:false,
                counterA:0,
                displayImage:false,
                srcDisplay:'',
                buttonP:false,
                recordingNow:false,
                recordAvailable:false,
                recorder:null,
				isClicked: false,
				isCompleted: true,
				currTab:null,
                ticked:true,
                uploading:false,
                recording:null,
                recordings:[],
                grading:{
                    pronunciation:null,
                    structure:null,
                    vocabulary:null,
                    fluency:null,
                    comprehension:null,
                    interaction:null,
                },
                AudioAttrs: {
					src: "",
					title: "Select an audio to play",
					coverRotate: false,
					progressBarColor: "#FCBD0B",
					indicatorColor: "0E2D5B",

				},
			}
		},

        methods: {

            capitalizeLastWord(name) {
			const words = name.split(' ');
			if (words.length === 0) return name; // Handle empty string
			const lastWord = words.pop().toUpperCase(); // Capitalize the last word
			return [...words, lastWord].join(' '); // Join the words back together
		},
        
      callback (data) {
        console.log(data)
      },
      enlargeImage(src){
        this.displayImage = true
        this.srcDisplay = src
      },
      closeImage(){
        this.displayImage = false
      },
        ...mapActions({
            getassessment: 'Assessments/getAssessment',
			getcustomers: 'Users/getAllCustomers',
            getTasks: 'Assessments/getAllTasks',
            getquestions: 'Questions/getAllQuestions',
            getsample: 'Samples/getSample',
            getsettings: 'Settings/getAllSettings',
        }),
			changeURL(event) {
				this.AudioAttrs.src = event.currentTarget.value
				this.AudioAttrs.title = event.currentTarget.name
			},
        playButtn(){
            document.getElementsByClassName('audio__player-play')[0].click()
			},
        startRecording(name) {
        this.AudioAttrs.title= "Playing the " + name + " recording."
        this.recordingNow=true
        this.recorder.start().then(() => {
        }).catch((e) => {
            console.error(e);
        });
        },
        stopRecording(id,name) {
        this.recorder.stop().getMp3().then(([buffer, blob]) => {
            let today = new Date().toString()
            let text = name + this.assessment.customer_id + today +".mp3"
            const file = new File(buffer, text, {
            type: blob.type,
            lastModified: Date.now()
            });
            let attachment =  {
                recording: file,
                task: id,
            loader:"", url:URL.createObjectURL(file)
            }
            const getFormData = object => Object.entries(object).reduce((fd, [key, val]) => {
						if (Array.isArray(val)) {
							val.forEach(v => fd.append(key, v))
						} else {
							fd.append(key, val)
						}
						return fd
					}, new FormData())
					attachment.loader = 'load'
					RecordingService.sendRecording(this.id, getFormData(attachment)).then(() => {
						Swal.fire({
                        icon:"success",
                        title:"Success!",
                        text:"The recording has been submitted!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timerProgressBar: true,
                        timer: 3000,})
						
						attachment.loader='yes'
							
					}).catch((error)=>{
						let message= Object.keys(error.response.data).at(0) + " : " + error.response.data[Object.keys(error.response.data).at(0)]
          Swal.fire({
          icon:"error",
          title:"It appears you have encountered an error!",
          text:message,
          showConfirmButton: true,
				showDenyButton: true,
				confirmButtonColor: '#0E2D5B',
				denyButtonColor: '#990c53',
        denyButtonText:"Ignore",
        confirmButtonText:"Send Report",
			}).then((result) => {
				if (result.value) {

          let user = JSON.parse(localStorage.getItem('userdetail'))
          ReportServices.sendErrorReport({error_page:window.location.href,error_api:error.response.config.url,error_message:message,email:user.email })
				}
			})
						attachment.loader='no'
					})
            this.recordings.push( attachment)
            
            this.recordAvailable= true;

        }).catch((e) => {
            console.error(e);
        });
        },
        assessmentCustomer(sentID){
			return this.customers.find(cus => cus.id === sentID)},	

        questionTask(name){
            return this.sampledQuestions.filter(q => q.for_task === name )
        },

        questionFilter(questionList, type){
            return questionList.filter(q => q.type === type)
        },
        sort_by_id() {
        return function (elem1, elem2) {
          if (elem1.id < elem2.id) {
            return -1;
          } else if (elem1.id > elem2.id) {
            return 1;
          } else {
            return 0;
          }
        };
      },
        sortedTask(){
            return this.tasks.sort(this.sort_by_id())
        },
        submitReport(taskid,name){
            this.counterA++
            this.stopRecording(taskid,name)
            if(this.sortedTask().find(t =>  t.id === taskid+1)){

                document.getElementById(taskid+1).click()
                this.currTab=taskid+1;
                this.startRecording(name)
            }
            else{
                this.recordingNow=false
               this.summary=true
            }
            
                    
        },
        finishAssessment(){
			
								AssessmentService.pending(this.id).then(()=>{
                                router.push({name: 'AssessmentsTab', params: {currTab: "pending" }})
                                this.$forceUpdate()
                            })
                        
				
            
        }

		},

        computed: {
        ...mapState({
            customers: state => state.Users.customers,
			assessment: state => state.Assessments.assessment,
            tasks: state => state.Assessments.tasks,
            questions: state => state.Questions.questions,
            sample: state => state.Samples.samples,
            settings: state => state.Settings.settings
        }),
        sampledQuestions(){
            return this.sample.questions
        }
		},

        created() {
            this.recorder = new MicRecorder({
        bitRate: 192
        });
			this.emptyState = 'Sorry!, we couldnt find matching results'
            
			this.getTasks()
            this.getassessment(this.id)
			this.getquestions()
            this.getsettings()
            watchEffect(()=>{
                if(this.assessment.sample){

                    this.getsample(this.assessment.sample)
                    this.currTab = this.sortedTask()[0].id
                }
            } )

		},
    }
</script>

<style>

</style>